import React, { useEffect, useContext } from "react"
import logo from '../logo.svg';
import Header from "../components/shared/Header";
import Layout from "../components/shared/Layout";
import HeroSection from "../components/HeroSection";
import Carousel from "../components/Carousel";

function HomePage() {
  return (
    <Layout title="Home">
      <Header />
      <div className="container-fluid">
        <div className="px-3 md:px-5">
          <HeroSection />
          <Carousel />
        </div>
      </div>
    </Layout>
  )
}

export default HomePage