import React, { useEffect, useContext } from "react"

function Button(props) {
    const { light, dark } = props
    return (
        <>
            {light && (
                <button className="py-2 px-4 item-center border rounded-full text-neDarkBlue hover:bg-neDarkBlue hover:text-neWhite">
                    {props.children}
                </button>
            )}

            {dark && (
                <button className="py-2 px-4 item-center border rounded-full text-neWhite bg-neBlue hover:bg-neDarkBlue hover:opacity-70">
                    {props.children}
                </button>
            )}
        </>
    )
}

export default Button