import React, { useEffect, useContext } from "react"
import { Link } from "react-router-dom"
import Button from "../form/Button";
import StateContext from "../../context/StateContext";


function Navs(props) {
    const navClass = 'text-neDarkBlue hover:text-neDarkBlue hover:underline hover:underline-offset-8 '
    const selected = 'underline underline-offset-8'
    const appState = useContext(StateContext)
    const { title } = appState
    console.log(title)

    return (
        <>
            <Link to="/" className={`${navClass + (title === 'Home' ? selected : '')}`}>Home</Link>
            <Link to="about.html" className={`${navClass + (title === 'About' ? selected : '')}`}>About</Link>

            <div className="nav-item dropdown">

                <Link to="/services" className={`${navClass} ${props.sideBar ? "" : "dropdown-toggle"}`} data-bs-toggle="dropdown">
                    Services
                </Link>
                {!props.sideBar && (
                    <div className="dropdown-menu m-0">
                        <Link to="feature.html" className="dropdown-item">Branding</Link>
                        <Link to="feature.html" className="dropdown-item">Website Design</Link>
                        <Link to="gallery.html" className="dropdown-item">Website Development</Link>
                        <Link to="attraction.html" className="dropdown-item">Website Maintenance</Link>
                        <Link to="package.html" className="dropdown-item">App Development</Link>
                        <Link to="team.html" className="dropdown-item">SEO</Link>
                    </div>
                )}
            </div>
            <Link to="blog.html" className={`${navClass + (title === 'Our Process' ? selected : '')}`}>Our Process</Link>
            <Link to="service.html" className={`${navClass + (title === 'Contact' ? selected : '')}`}>Contact</Link>

            <Link to="contact.html">
                <Button dark>
                    Let's talk
                </Button>
            </Link>
        </>
    )
}

export default Navs